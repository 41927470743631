'use strict'

const self = {
  container: document.querySelector('.is-hide-columns'),
  btn: null,

  init: () => {
    // View all is not being used right now, so not initializing this
    // self.setupElements()
    // self.registerEvents()
  },

  setupElements: () => {
    self.container = document.querySelector('.is-hide-columns');
    self.btn = document.querySelector('.view-all-btn');
  },

  registerEvents: () => {
    let isShow = false;
    self.btn.addEventListener('click', function (e) {
      self.container.classList.toggle('is-show');
      isShow = !isShow;
      if (isShow) {
        self.btn.innerHTML = 'View Less'
      } else {
        self.btn.innerHTML = 'View All'
      }
    });
  }

}

export default self