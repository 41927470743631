'use strict';

// Dependancies
import "babel-polyfill"

// Modules
import PageTransition from './modules/pageTransition'
import Navigation from './modules/navigation'
import JournalNav from './modules/journalNav'
import ZoomInOutImage from './modules/zoomInOutImage'
import PanoramaViewer from './modules/panoramaViewer'
import FormDisplayOn from './modules/formDisplayOn'
import WestEndMap from './modules/westEndMap'
import ScrollDownForm from './modules/scrollDownForm'
import FloorplansFilter from './modules/floorplansFilter'
import HomeSlider from './modules/homeSlider'
import NeighourhoodSlideshow from './modules/neighourhoodSlideshow'
import Accordion from './modules/accordion'
import ViewAll from './modules/viewAll'
import ViewsTab from './modules/viewsTab'
import SalesforceForm from './modules/salesforceForm'
import ScrollTo from './modules/scrollTo'


// Global Functionality
PageTransition.init()
Navigation.init()
ScrollDownForm.init()
SalesforceForm.init()

// Conditional Functionality
const m = [{
    o: JournalNav,
    i: JournalNav.container
  },
  {
    o: ZoomInOutImage,
    i: ZoomInOutImage.container
  },
  {
    o: FormDisplayOn,
    i: FormDisplayOn.container
  },
  {
    o: PanoramaViewer,
    i: PanoramaViewer.container
  },
  {
    o: WestEndMap,
    i: WestEndMap.container
  },
  {
    o: FloorplansFilter,
    i: FloorplansFilter.container
  },
  {
    o: HomeSlider,
    i: HomeSlider.container
  },
  {
    o: NeighourhoodSlideshow,
    i: NeighourhoodSlideshow.container
  },
  {
    o: Accordion,
    i: Accordion.container
  },
  {
    o: ViewAll,
    i: ViewAll.container
  },
  {
    o: ViewsTab,
    i: ViewsTab.container
  }
].map(a => (a.i) ? a.o.init() : null)
