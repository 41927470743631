'use strict'

import throttle from 'lodash.throttle'
import { TweenMax, TimelineLite } from 'gsap/umd/TweenMax'

const self = {
  container: null,
  body: null,
  lastScrollTop: 0,
  menu: null,
  mainNav: null,
  navList: null,

  init: () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements: () => {
    self.body = document.body
    self.menu = [...document.querySelectorAll('.menu__container')]
    self.mainNav = document.querySelector('.main-nav')
    self.navList = document.querySelectorAll('.main-nav li')
    self.closeBtn = document.querySelector('.header-label-list__item .close-btn')
    self.headerLabel = document.querySelector('.header-label')
    self.header = document.querySelector('header.main-header .flex-box')
    self.btnMore = self.mainNav.querySelector('.btn__more')
  },

  registerEvents: () => {
    // window.addEventListener('scroll', throttle(self.onScroll, 16))
    self.menu.forEach(m => {
      m.addEventListener('click', () => {
        if (!document.body.classList.contains('is-animating')) {
          self.toggle()
        }
      })
    })

    self.closeBtn.addEventListener('click', (e) => {
      self.headerLabel.classList.remove('open');
      self.header.style.transform = 'translateY(0px)';
      e.stopImmediatePropagation();
    })

    self.header.style.transform = 'translateY(20px)';
    setTimeout(() => {
      self.headerLabel.classList.add('open');
    }, 500);

    self.btnMore.addEventListener('click', self.toggleMore)
  },

  toggleMore : ({ currentTarget : button }) => self.mainNav.classList.toggle('show-more'),

  onScroll: e => {
    let st = window.pageYOffset || document.documentElement.scrollTop
    const marginTop = 200
    if (st > self.lastScrollTop && st > marginTop) {
      self.body.classList.add('is-down')
      self.body.classList.remove('is-up')
    } else {
      if (st > marginTop) {
        self.body.classList.remove('is-down')
        self.body.classList.add('is-up')
      } else {
        self.body.classList.remove('is-down')
        self.body.classList.remove('is-up')
      }
    }
    self.lastScrollTop = st
  },

  toggle: () => {
    if (!self.body.classList.contains('is-open')) {
      self.openNav()
      self.mainNav.style.pointerEvents = "auto";
    } else {
      self.closeNav()
      self.mainNav.style.pointerEvents = "none";
    }
  },

  openNav: () => {
    const tlOpen = new TimelineLite()
    tlOpen
      .set(self.body, { className: '+=is-animating', overflow: 'hidden' })
      .set(self.mainNav, { display: 'block' })
      .set(self.navList, { y: '20px', autoAlpha: 0 })
      .set(self.body, { className: '+=is-open' })
      .to(self.mainNav, 1, { autoAlpha: 1, ease: Expo.easeOut })
      .staggerTo(self.navList, 1, { y: '0%', autoAlpha: 1, ease: Expo.easeOut }, 0.1, '-=0.2')
      .set(self.body, { className: '-=is-animating' })
  },

  closeNav: () => {
    const tlClose = new TimelineLite()
    tlClose
      .set(self.body, { className: '+=is-animating' })
      .set(self.body, { className: '-=is-open' })
      .to(self.mainNav, 1.0, { autoAlpha: 0, ease: Expo.easeOut })
      .set([self.mainNav, self.navList], { clearProps: 'all' })
      .set(self.body, { className: '-=is-animating', overflow: 'visible' })
  }
}

export default self
