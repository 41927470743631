'use strict'

import $ from 'jquery';
import selectize from 'selectize';

const self = {
  footer: document.querySelector('footer'),
  btn: document.querySelector('.btn-waitlist'),
  dpsArr: [],

  init: () => {
    // If this is the Sign Up page, then run this JS ....
    if ( document.querySelector('#sign-up') ) {

      // Note, using document.querySelector('#sign-up') specifically,
      // so that the JS will always RECHECK for the element
      
      self.setupElements()
      self.registerEvents()
    } else {
      // ... otherwise, make sure to show Book a Viewing again.
      self.footer.classList.remove('minimal-footer');
      self.btn.style.display = "block";
    }
  },

  validateCaptcha: () => {
    
    var isCaptchaValidated = false;
    var response = grecaptcha.getResponse();
    if(response.length == 0) {
        isCaptchaValidated = false;
        toast('Please verify that you are a Human.');
    } else {
        isCaptchaValidated = true;
    }

    console.log(isCaptchaValidated);
  },

  setupElements: () => {
    // Sign Up / Waitlist form
    self.requiredInputs = [...document.querySelectorAll('input.input-required')]
    self.selectTags = [...document.querySelectorAll('.input-group select')];

    self.optIn = document.getElementById('00Nj0000000kXHD');

    // set up selectize
    $('#00Nf100000CNWPU').selectize({
      items: ['']
    });
    $('#00Nf100000CakrT').selectize({
      items: ['']
    });
    $('#00Nf100000CakrY').selectize({
      items: ['']
    });
    $('#00Nj000000AITER').selectize({
      items: ['']
    });

  },

  registerEvents: () => {
    document.querySelector('#sign-up').addEventListener('submit', self.onSubmit)

    // Since this form is its' own page, we need to hide some things!
    self.footer.classList.add('minimal-footer');
    self.btn.style.display = "none";
  },

  onSubmit: e => {

    let action = ''
    let formIsValid = true

    // Check input text field
    self.requiredInputs.map(input => {
      switch (input.type) {
        case 'text':
          formIsValid = input.value.length > 0
          action = (!formIsValid) ? 'add' : 'remove'
          input.parentNode.classList[action]('error')
          break;
        case 'email':
          formIsValid = self.isValid({
            email: input.value
          })
          action = (!formIsValid) ? 'add' : 'remove'
          input.parentNode.classList[action]('error')
          break;
      }
    })

    // Check select field
    self.selectTags.forEach((select) => {
      if (!select.value) {
        formIsValid = false
        select.parentNode.classList.add('error')
      } else {
        select.parentNode.classList.remove('error')
      }
    });

    // Check optin
    if (!self.optIn.checked) {
      formIsValid = false
      self.optIn.parentNode.parentNode.classList.add('error')
    } else {
      self.optIn.parentNode.parentNode.classList.remove('error')
    }

    if (!formIsValid) e.preventDefault()
  },

  isValid: ({
    email
  }) => {
    // Validate Email
    if (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }

}
export default self