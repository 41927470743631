'use strict'

import { TweenMax, TimelineLite, TweenLite } from "gsap/umd/TweenMax"
import CustomBodymovin from './bodyMovin'
import ScrollMagic from 'scrollmagic';

const self = {
  container :         document.querySelector('.westend-essentials'),
  btn :               null,
  navList :           null,
  contentList :       null,
  mapList :           null,
  isClick :           true,
  controller:         null,
  scene :             null,
  videoList:          null,
  imageMapContainer:  null,
  imageMapList :      null,

  mapElements : {
    mapBase :           null,
    recreationMap :     null,
    diningMap :         null,
    shoppingMap :       null,
    artwalkMap :        null,
    dogwalkMap :        null,
    transportationMap : null,
  },

  init : () => {
    self.setupElements()
    self.registerEvents()

    self.getMapHeight()

    self.controller = new ScrollMagic.Controller();
    self.scene = new ScrollMagic.Scene({triggerElement: self.container, triggerHook: 0.1})
    .addTo(self.controller)
    .on("enter", function(e){

      self.mapElements.mapBase.play();

      if(self.isClick){
        TweenLite.to(self.btn, 0.2, {rotation: '+=135', ease: Power1.easeOut});
        TweenMax.staggerTo(self.navList, 0.1, {autoAlpha: 1, y: 0, ease: Power1.easeOut}, -0.05);
      }

    });


  },

  setupElements : () => {
    self.container = document.querySelector('.westend-essentials');
    self.btn = document.querySelector('.essentials__nav__plus');
    self.navList = [...document.querySelectorAll('.essentials__nav__list')];
    self.contentList = [...document.querySelectorAll('.essentials__contents__list')];
    self.mapList = [...document.querySelectorAll('.map__pointer__list')];
    self.videoList = [...document.querySelectorAll('.essentials__videos__inner')];
    self.imageMapContainer = document.querySelector('.map__images');
    self.imageMapList = [...document.querySelectorAll('.map__images__bg')];

    self.mapElements.mapBase = new CustomBodymovin({
      elm: document.querySelector('.essentials__map__base'),
      animationData: 'mapBase'
    });
    self.mapElements.recreationMap = new CustomBodymovin({
      elm: document.querySelector('.map__pointer__list[data-map="recreation"]'),
      animationData: 'recreation'
    });
    self.mapElements.diningMap = new CustomBodymovin({
      elm: document.querySelector('.map__pointer__list[data-map="dining"]'),
      animationData: 'dining'
    });
    self.mapElements.shoppingMap = new CustomBodymovin({
      elm: document.querySelector('.map__pointer__list[data-map="shopping"]'),
      animationData: 'shopping'
    });
    self.mapElements.artwalkMap = new CustomBodymovin({
      elm: document.querySelector('.map__pointer__list[data-map="artwalk"]'),
      animationData: 'artwalk'
    });
    self.mapElements.dogwalkMap = new CustomBodymovin({
      elm: document.querySelector('.map__pointer__list[data-map="dogwalk"]'),
      animationData: 'dogwalk'
    });
    self.mapElements.transportationMap = new CustomBodymovin({
      elm: document.querySelector('.map__pointer__list[data-map="transportation"]'),
      animationData: 'transportation'
    });
  },

  registerEvents : () => {
    TweenLite.to(self.btn, 1, {rotation: 180, ease: Power1.easeOut});
    self.btn.addEventListener('click', (e) => {
      self.displayNav();
    });

    self.navList.forEach((nav)=>{
      nav.addEventListener('click', function(e){

        // add active to nav
        self.navList.forEach((list)=>{
          list.classList.remove('is-active');
        });
        this.classList.add('is-active');
        self.displayContentMap(this.dataset.name);
      });
    });

    // video hover
    self.videoList.forEach((video)=>{
      video.addEventListener('mouseover', function (e) {
        TweenLite.set('.map__clip', {autoAlpha: 1});
        TweenLite.to('.map__images .is-active', 0.6, {autoAlpha: 1, ease: Power1.easeOut});
        TweenLite.to('.map__images .is-active', 3, {scale: 1.05, ease: Power1.easeOut});
      });
      video.addEventListener('mouseleave', function (e) {
        TweenLite.to('.map__images .is-active', 0.3, {autoAlpha: 0 ,scale: 1, ease: Power1.easeOut});
      });
    });

    window.addEventListener('resize', (e) => {
      self.getMapHeight();
    });

    
  },

  getMapHeight : () => {
    const mapClip = document.querySelector('.map__clip__container');
    if(self.imageMapList[0]){

      setTimeout(() => {
        const containerHeight = self.imageMapList[0].clientHeight;
        self.imageMapContainer.style.height = `${containerHeight}px`;
        mapClip.style.height = `${containerHeight}px`;
      }, 100);
    }
  },

  displayNav : () => {
    self.isClick = !self.isClick;
    if(self.isClick){
      TweenLite.to(self.btn, 0.2, {rotation: '+=135', ease: Power1.easeOut});
      TweenMax.staggerTo(self.navList, 0.1, {autoAlpha: 1, y: 0, ease: Power1.easeOut}, -0.05);
    } else {
      TweenLite.to(self.btn, 0.2, {rotation: '+=135', ease: Power1.easeOut});
      TweenMax.staggerTo(self.navList, 0.1, {autoAlpha: 0, y: '40px', ease: Power1.easeOut}, 0.05);
    }
  },

  displayContentMap : (selectedNav) => {
    self.contentList.forEach((content)=>{
      content.style.display = 'none';
      if(selectedNav === content.dataset.list){
        content.style.display = 'block';
        const theLists = content.querySelectorAll('li');
        TweenMax.staggerFromTo(theLists, 0.3, {autoAlpha: 0, x: '30px'}, {autoAlpha: 1, x: 0, ease: Power1.easeOut}, 0.05);
      }
    });

    self.mapList.forEach((map)=>{
      map.style.display = 'none';
      if(selectedNav === map.dataset.map){
        map.style.display = 'block';
      }
    });

    switch (selectedNav) {
      case 'recreation':
        self.mapElements.recreationMap.stop();
        self.mapElements.recreationMap.play();
      break;
      case 'dining':
        self.mapElements.diningMap.stop();
        self.mapElements.diningMap.play();
      break;
      case 'shopping':
        self.mapElements.shoppingMap.stop();
        self.mapElements.shoppingMap.play();
      break;
      case 'artwalk':
        self.mapElements.artwalkMap.stop();
        self.mapElements.artwalkMap.play();
      break;
      case 'dogwalk':
        self.mapElements.dogwalkMap.stop();
        self.mapElements.dogwalkMap.play();
      break;
      case 'transportation':
        self.mapElements.transportationMap.stop();
        self.mapElements.transportationMap.play();
      break;
    }

    self.videoList.forEach((video)=>{
      video.querySelector('video').pause();
      TweenMax.to(video, 0.3, {autoAlpha: 0, x: '-40px'});
      if(selectedNav === video.dataset.video){
        video.querySelector('video').play();
        TweenMax.fromTo(video, 0.3, {autoAlpha: 0, x: '40px'}, {autoAlpha: 1, x: 0, ease: Power1.easeOut, delay: 0.2});
      }
    });

    self.imageMapList.forEach((imageMap)=>{
      imageMap.classList.remove('is-active');
      if(selectedNav === imageMap.dataset.mapimage){
        imageMap.classList.add('is-active');
      }
    });

  }
}

export default self
