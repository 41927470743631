'use strict';
import { TweenLite } from 'gsap/umd/TweenMax';

const self = {
  btnWaitList: null,
  formWrapper: null,
  id: null,

  init: () => {
    self.setupElements();
    self.closeForm();
    //self.btnWaitList.addEventListener('click', e => self.scrollDowntoForm());
    // self.headerLabel.addEventListener('click', e => self.scrollDowntoForm());
    setTimeout(() => {
      self.hashlocation();
    }, 300);
  },

  setupElements: () => {
    self.btnWaitList = document.querySelector('.btn-waitlist');
    self.formWrapper = document.querySelector('.form-wrapper');
    // self.headerLabel = document.querySelector('.header-label');
  },

  hashlocation: () => {
    self.id = location.hash.replace('#', '');
    if (self.id == 'book-a-view') self.scrollDowntoForm();
  },

  scrollDowntoForm: () => {
    const tlDisplay = new TimelineLite();
    tlDisplay
      .set('body', {
        className: '-=is-open'
      })
      .set('.main-nav', {
        autoAlpha: 0,
        ease: Expo.easeOut
      })
      .set('footer', {
        className: '+=js-open'
      })
      .set(self.formWrapper, {
        display: 'block'
      })
      .to(
        self.formWrapper,
        0.6,
        {
          autoAlpha: 1,
          y: 0
        },
        '-=0.3'
      )
      .set(['.main-nav', '.main-nav li'], {
        clearProps: 'all'
      })
      .set(['body', 'html'], {
        overflow: 'visible'
      });
    TweenLite.to(window, 1, {
      scrollTo: 'footer'
    });
  },

  closeForm: () => {
    TweenLite.set('footer', {
      className: '-=js-open'
    });
    TweenLite.set('.form-wrapper', {
      clearProps: 'all'
    });
  }
};

export default self;
