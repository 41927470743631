'use strict'

import ScrollMagic from 'scrollmagic';
import { TweenMax, TimelineLite } from "gsap/umd/TweenMax"; 
import ScrollToPlugin from "gsap/umd/ScrollToPlugin";

import debug from '../lib/debug.addIndicators';


let self

export default self = {
  container : document.querySelector('.main-single-journal'),
  nav : null,
  navList : null,
  footer : null,
  journalHeight: null,
  navHeight : null,
  stickController: null,
  stickScene: null,


  init : () => {

    self.setupElements();
    self.navIndicator();
    self.registerEvents();

    // Journal height + offset
    self.journalHeight = document.querySelector('.s-journal-center').clientHeight + 100;
    // Nav Height + top margin
    self.navHeight = document.querySelector('.s-journal-left').clientHeight + 100;

    self.stickyNav(self.journalHeight, self.navHeight);
  },

  setupElements : () => {
    self.container = document.querySelector('.main-single-journal');
    self.nav = document.querySelector('.s-journal-left');
    self.navList = [...document.querySelectorAll('.journal-nav__list a')];
    self.footer = document.querySelector('footer');
    self.stickController = null;
    self.stickScene = null;
  },

  registerEvents : () => {
    window.addEventListener('resize', (e) => {
      self.journalHeight = document.querySelector('.s-journal-center').clientHeight + 100;
      self.navHeight = document.querySelector('.s-journal-left').clientHeight + 100;
      self.stickScene.duration(`${self.journalHeight - self.navHeight}px`);
    });
  },

  navIndicator : () => {
    // Init controller
    const controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: .2,
        reverse: true
      }
    });

    let scenes = {}

    self.navList.forEach((list, index)=>{
      const hash = `#image${index + 1}`;
      const listId = `image${index + 1}-anchor`;
      const id = `image${index + 1}`;

      scenes[index] = {
        [hash]: listId,
        'height': document.getElementById(id).clientHeight
      };
    });

    for(let key in scenes){
      if (!scenes.hasOwnProperty(key)) continue;
      const eachObj = scenes[key];

      for(let prop in eachObj){
        if(!eachObj.hasOwnProperty(prop) || prop == 'height') continue;
    
        new ScrollMagic.Scene({ triggerElement: prop, duration: eachObj['height']})
          .setClassToggle('#' + eachObj[prop], 'active')
          // .addIndicators({name: "quote"})
          .addTo(controller);
      }
    }

    controller.scrollTo((target) => {
      TweenMax.to(window, 1, {
        scrollTo : {
          y : target - 100,
          autoKill : true // Allow scroll position to change outside itself
        },
        ease : Cubic.easeInOut
        });
    });

    //  Bind scroll to anchor links using Vanilla JavaScript
    const anchorNav = document.querySelectorAll('.journal-nav a');
    [...anchorNav].forEach((nav)=>{
      nav.addEventListener('click', (e) => {
        const id = nav.getAttribute('href');
    
        if(id !== null) {
          if(id.length > 0) {
            e.preventDefault();
            controller.scrollTo(id);
            if(window.history && window.history.pushState) {
              history.pushState("", document.title, id);
            }
          }
        }
      });
    });
  },

  stickyNav : (journalHeight, navHeight) => {

    self.stickController = new ScrollMagic.Controller();
    self.stickScene = new ScrollMagic.Scene({triggerElement: '.s-journal-center', triggerHook: 0, duration: `${journalHeight - navHeight}px`, offset: -100})
                            .addTo(self.stickController)
                            // .addIndicators({name: "quote"})
                            .on("enter", function(e){
                              if(self.stickScene.state() === 'DURING'){
                                self.nav.classList.add('is-enter');
                                self.nav.classList.remove('is-out');
                              }
                            })
                            .on("leave", function(e){

                              if(self.stickScene.state() === 'AFTER'){
                                self.nav.classList.remove('is-enter');
                                self.nav.classList.add('is-out');
                              };

                              if(self.stickScene.state() === 'BEFORE'){
                                self.nav.classList.remove('is-enter');
                                self.nav.classList.remove('is-out');
                              };

                            });


  }
}