'use strict'

const self = {
  container: document.querySelector('[data-accordion]'),
  toggles: null,

  init: () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements: () => {
    self.toggles = [...document.querySelectorAll('.accordion-toggle')];
    self.openClass = 'accordion-toggle--open';
  },

  registerEvents: () => {
    self.toggles.forEach(toggle => {
      toggle.querySelector('.feature-text').addEventListener('click', () => {
        if ( ! toggle.classList.contains(self.openClass) ) {
          toggle.classList.add(self.openClass);
        } else {
          toggle.classList.remove(self.openClass);
        }
      });
    })
  }

}

export default self
