'use strict'

const self = {
  container: document.querySelector('.views-container'),

  init: () => {
    self.setupElements()
    self.registerEvents()
  
    document.querySelectorAll('.views-container').forEach(view => {
        view.classList.add('views-container--init');
    });
  },

  setupElements: () => {
    self.levelBtns = [...document.querySelectorAll('[data-levelbtn]')]
    self.views = [...document.querySelectorAll('[data-level]')]
  },

  registerEvents: () => {
    self.levelBtns.forEach(btn => {
      btn.addEventListener('click', e => {
        self.levelBtns.forEach(btn => {
          btn.classList.remove('is-active')
        })

        const selectedBtn = e.currentTarget
        const selectedLevel = selectedBtn.dataset.levelbtn
        selectedBtn.classList.add('is-active')

        self.views.forEach(view => {
          if (view.dataset.level === selectedLevel) {
            view.classList.add('is-active')
          } else {
            view.classList.remove('is-active')
          }
        })
      })
    })
  }
}

export default self
