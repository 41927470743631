'use strict'

import Swiper from 'swiper'

// Swiper ability for panoramic images!
// Allows users to drag the image in floorplan pages left or right

const self = {
  container: document.querySelector('.panoramic-image'),

  init: () => {
    self.setupElements()
    self.setupEvents()
  },

  setupElements: () => {
    self.container = document.querySelector('.swiper-container')
    self.knob = document.querySelector('.swiper-knob__dial');
    self.image = document.querySelector('.panoramic-image')
    self.next = document.querySelector('.viewer-btn-next')
    self.prev = document.querySelector('.viewer-btn-prev')
    self.view = ( self.image.hasAttribute('data-view') ) ? self.image.getAttribute('data-view') : 0 
    self.sliderBtnTranslate = 500;
  },

  setupEvents: () => {      
    self.viewSlider() // initalize the slider!

    // Give arrows some touchmove functionality if users want to click instead of drag
    self.next.addEventListener('click', () => {
      self.container.swiper.translateTo((self.container.swiper.translate-self.sliderBtnTranslate), 2000)
    });

    self.prev.addEventListener('click', () => {
      self.container.swiper.translateTo((self.container.swiper.translate+self.sliderBtnTranslate), 2000)
    });
  },

  viewSlider: () => {
    const slider = new Swiper('.viewer', {
      slidesPerView: 'auto',
      freeMode: true,
      freeModeMomentumBounce: false,
      loop: true,
      slidesOffsetBefore: self.setViewPosition(self.view),
    });
  },

  setViewPosition: ( position ) => {
    let container = self.container.clientWidth/2;
    let view = (self.image.width/5);
    let precision_offset = (view*.5);

    if ( position == 'East' ) {
      return container-(view-precision_offset);
    } else if ( position == 'South' ) {
      return container-(view+view-precision_offset);
    } else if ( position == 'West' ) {
      return container-(view+view+view-precision_offset);
    } else if ( position == 'North' ) {
      return container-(view+view+view+view);
    } else if ( position == 'North East' ) {
      return container-(view+view+view+view+(view/2));
    } else if ( position == 'North West' ) {
      return container-(view+view+view);
    } else if ( position == 'South West' ) {
      return container-(view+view-precision_offset+(view/2));
    } else {
      return 0;
    }
  }

}

export default self
