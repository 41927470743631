'use strict'

const self = {
  lastElementClicked: null,

  init: () => {
    self.scrollToLinks = [...document.querySelectorAll('[data-scrollTo]')];
    self.scrollToLinks.forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault();
        let target = document.querySelector(link.getAttribute('href')).getBoundingClientRect();
        self.scrollTo(target.top, 1500);
      })
    });
  },

  scrollTo: (to, duration) => {
      const
      element = document.scrollingElement || document.documentElement,
      start = element.scrollTop,
      change = to - start,
      startDate = +new Date(),
      // t = current time
      // b = start value
      // c = change in value
      // d = duration
      easeInOutQuad = function(t, b, c, d) {
          t /= d/2;
          if (t < 1) return c/2*t*t + b;
          t--;
          return -c/2 * (t*(t-2) - 1) + b;
      },
      animateScroll = function() {
          const currentDate = +new Date();
          const currentTime = currentDate - startDate;
          element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
          if(currentTime < duration) {
              requestAnimationFrame(animateScroll);
          }
          else {
              element.scrollTop = to;
          }
      };
      animateScroll();
      console.log('hi')
  }

}

export default self