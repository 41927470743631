'use strict'

import WheelIndicator from 'wheel-indicator'
import ScrollMagic from 'scrollmagic'
import Swiper from 'swiper'
import {
  TimelineLite,
  TweenLite
} from 'gsap/umd/TweenMax'
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin'
import debug from '../lib/debug.addIndicators'

const self = {
  container: document.querySelector('.main-home'),
  controller: null,
  scene: null,
  sceneGoingUp: null,
  bgImage: null,
  swiperBuilding: null,
  indicator: null,
  skipBtn: null,
  id: null,

  init: () => {
    document.documentElement.style.overflow = 'visible'
    setTimeout(() => {

    }, 1000)
    // self.setupElements()
    // self.scrollDowntoForm()
    // self.closeForm()
    // self.hashlocation()
    //
    // if (window.innerWidth > 870) {
    //   self.indicatorInit()
    //   self.watcherInit()
    //   self.swiperBuilding.init()
    // }
  },

  hashlocation: () => {
    self.id = location.hash.replace('#', '')
    if (self.id == 'waitlist') self.initOpen(self.id)
  },

  initOpen: () => {
    if (window.innerWidth > 870) {
      self.scene.enabled(false)
      self.sceneGoingUp.enabled(false)
      setTimeout(() => {
        self.scene.enabled(true)
        self.sceneGoingUp.enabled(true)
      }, 1000)
    }

    setTimeout(() => {
      self.openForm(0)
    }, 500)
  },

  setupElements: () => {
    self.container = document.querySelector('.main-home')
    self.bgImage = document.querySelectorAll('.home-nav__img')
    self.txt = document.querySelectorAll('.home-nav__txt')
    self.controller = new ScrollMagic.Controller()

    self.swiperBuilding = new Swiper('.main-home .swiper-container', {
      init: false,
      direction: 'horizontal',
      slidesPerView: 1,
      effect: 'fade',
      speed: 1000,
      navigation: {
        nextEl: '.swiper-btn-next'
      },
      on: {
        slideChange: function () {
          switch (this.activeIndex) {
            case 0:
              TweenLite.to(self.bgImage[0], 0.6, {
                y: '0%'
              })
              TweenLite.to(self.txt[0], 0.6, {
                y: '0%'
              })
              break
            case 1:
              TweenLite.to(self.bgImage[0], 0.6, {
                y: '-100%'
              })
              TweenLite.to(self.txt[0], 0.6, {
                y: '100%'
              })
              TweenLite.to(self.bgImage[1], 0.6, {
                y: '0%'
              })
              TweenLite.to(self.txt[1], 0.6, {
                y: '0%'
              })
              break
            case 2:
              TweenLite.to(self.bgImage[1], 0.6, {
                y: '-100%'
              })
              TweenLite.to(self.txt[1], 0.6, {
                y: '100%'
              })
              TweenLite.to(self.bgImage[2], 0.6, {
                y: '0%'
              })
              TweenLite.to(self.txt[2], 0.6, {
                y: '0%'
              })
              break
            case 3:
              TweenLite.to(self.bgImage[2], 0.6, {
                y: '-100%'
              })
              TweenLite.to(self.txt[2], 0.6, {
                y: '100%'
              })
              TweenLite.to(self.bgImage[3], 0.6, {
                y: '0%'
              })
              TweenLite.to(self.txt[3], 0.6, {
                y: '0%'
              })
              // remove in case 4 gets uncommented
              // start
              setTimeout(() => {
                document.documentElement.style.overflow = 'visible'
              }, 1000)
              if (window.innerWidth > 820) {
                self.indicator.turnOff()
              }
              break
              // end

            // case 4:
            //   TweenLite.to(self.bgImage[3], 0.6, {
            //     y: '-100%'
            //   })
            //   TweenLite.to(self.txt[3], 0.6, {
            //     y: '100%'
            //   })
            //   setTimeout(() => {
            //     document.documentElement.style.overflow = 'visible'
            //   }, 1000)
            //   if (window.innerWidth > 820) {
            //     self.indicator.turnOff()
            //   }

            //   break
          }
        }
      }
    })
  },

  watcherInit: () => {
    self.scene = new ScrollMagic.Scene({
        triggerElement: self.container,
        triggerHook: 0
      })
      .addTo(self.controller)
      // .addIndicators({
      //   name: 'container'
      // })
      .on('enter', function (e) {
        if (document.body.classList.contains('front') && e.scrollDirection == 'PAUSED') {
          document.documentElement.style.overflow = 'hidden'
          self.indicator.turnOn()
        }
      })

    const footer = document.querySelector('footer')
    self.sceneGoingUp = new ScrollMagic.Scene({
        triggerElement: footer,
        triggerHook: 1,
        offset: 100
      })
      .addTo(self.controller)
      .on('leave', function (e) {
        TweenLite.to(window, 0.4, {
          scrollTo: self.container
        })

        if (document.body.classList.contains('front')) {
          document.documentElement.style.overflow = 'hidden'
          self.indicator.turnOn()
        }
      })
  },

  indicatorInit: () => {
    self.indicator = new WheelIndicator({
      elem: document.querySelector('.home-nav__container'),
      callback: e => {
        if (e.direction == 'up') {
          self.swiperBuilding.slidePrev()
        } else {
          self.swiperBuilding.slideNext()
        }
      }
    })
  },

  scrollDowntoForm: () => {
    const btnWaitList = [...document.querySelectorAll('.btn-waitlist')]
    btnWaitList.forEach(btn => {
      btn.addEventListener('click', e => {
        if (window.innerWidth > 820) {
          self.scene.enabled(false)
          self.sceneGoingUp.enabled(false)
          document.documentElement.style.overflow = 'visible'
          setTimeout(() => {
            self.scene.enabled(true)
            self.sceneGoingUp.enabled(true)
          }, 1000)
        }
        self.openForm()
      })
    })
  },

  openForm: (time = 1) => {
    const tlDisplay = new TimelineLite()
    tlDisplay
      .set('body', {
        className: '-=is-open'
      })
      .set('body', {
        className: '+=is-goingdown'
      })
      .set('.main-nav', {
        autoAlpha: 0,
        ease: Expo.easeOut
      })
      .set('footer', {
        className: '+=js-open'
      })
      .set('.form-wrapper', {
        display: 'block'
      })
      .to('.form-wrapper', 0.6, {
        autoAlpha: 1,
        y: 0
      }, '-=0.3')
      .set(['.main-nav', '.main-nav li'], {
        clearProps: 'all'
      })
      .set('body', {
        overflow: 'visible'
      })
    TweenLite.to(window, time, {
      scrollTo: 'footer'
    })
  },

  closeForm: () => {
    TweenLite.set('footer', {
      className: '-=js-open'
    })
    TweenLite.set('.form-wrapper', {
      clearProps: 'all'
    })
  }
}

export default self
