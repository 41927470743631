import bodymovin from 'bodymovin';
import logo from '../json/logo-green.json';
import mapBase from '../json/map/map-base.json';
import recreation from '../json/map/recreation.json';
import artwalk from '../json/map/art-walk.json';
import dining from '../json/map/dining.json';
import dogwalk from '../json/map/dog-walk.json';
import shopping from '../json/map/shopping.json';
import transportation from '../json/map/transit.json';

export default class CustomBodymovin {
  constructor(opts={}) {
    this.elm = opts.elm || document.createElement('div');
    this.animationData = opts.animationData;
    this.WIDTH = 1920;
    this.HEIGHT = 1080;
    this.init();
    this.initListener();
  }

  init() {
    this.selectAnimationData();
    this.anim = bodymovin.loadAnimation({
      container: this.elm,
      renderer: "svg",
      loop: false,
      autoplay: false,
      autoloadsegments:true,
      rendererSettings:{
        progressiveLoad:false
      },
      animationData: this.data
    });
  }

  initListener() {

    this._onResize();
    window.addEventListener('resize', () => {
      this._onResize();
    },false)

  }

  selectAnimationData() {
    switch (this.animationData) {
      case 'logo': 
        this.data = logo; 
      break;
      case 'mapBase': 
        this.data = mapBase;
      break;
      case 'recreation': 
        this.data = recreation;
      break;
      case 'artwalk': 
        this.data = artwalk;
      break;
      case 'dining': 
        this.data = dining;
      break;
      case 'dogwalk': 
        this.data = dogwalk;
      break;
      case 'shopping': 
        this.data = shopping;
      break;
      case 'transportation': 
        this.data = transportation;
      break;
    }
  }

  play(){
    this.anim.play();
  }
  stop(){
    this.anim.stop();
  }
  pause(){
    this.anim.pause();
  }
  destroy(){
    this.anim.destroy();
  }
  setSpeed(int){
    this.anim.setSpeed(int);
  }

  _onResize() {
    const ww = window.innerWidth;
    const wh = window.innerHeight;
    const ratio = ww / this.WIDTH;
    const scaleY = wh / (this.HEIGHT * ratio);
    const svg = this.elm.querySelector('svg');
  }

}