'use strict'

import $ from 'jquery';
import selectize from 'selectize';
import ScrollMagic from 'scrollmagic';
import {
  TweenLite,
} from "gsap";
import ScrollToPlugin from "gsap/umd/ScrollToPlugin";

const self = {
  container: document.querySelector('.main-floorplans'),
  currentFilterSelect: null,

  init: () => {
    self.setupElements()
    self.registerEvents()

    console.log( location.hash );
    if ( location.hash ) self.selectPlanType()
  },

  selectPlanType : () => {
    document.querySelector('aside.filter .input-group .selectize-input').click()

    setTimeout( () => {
      const value = location.hash.replace('#', '')
      const option = document.querySelector(`aside.filter .input-group .selectize-dropdown-content .option[data-value="${value}"]`)

      option.click()
    }, 100)

    setTimeout( () => {
      console.log( document.querySelector(`aside.filter .input-group .selectize-dropdown`) );
      document.querySelector(`aside.filter .input-group .selectize-dropdown`).setAttribute('style', 'display:none;')
    }, 500)
  },

  setupElements: () => {
    self.currentFilterSelect = {
      type: 'featured', // originally 'All'
      level: ['all'],
      featured: true
    }

    self.resultWrapper = document.querySelector('.results-container');
    self.resultContainer = document.querySelector('.results');
    self.resultsArray = [...document.querySelectorAll('.result')];
    self.typeSelect = document.getElementById('select-type');
    self.levelSelect = document.getElementById('select-level');
    self.currentFilterType = document.querySelector('.current-filter-type');
    self.filterBtn = document.querySelector('.filter-btn');
    self.mainContainer = document.querySelector('.main-floorplans');
    self.searchBtn = document.querySelector('.filter-search-btn');
    self.elevationStories = [...document.querySelectorAll('.elevations-container button')];

    self.filteredResults = null;

  },

  registerEvents: () => {
    // set up selectize
    $('#select-type').selectize({
      onChange: function (value) {
        self.currentFilterSelect.type = value;
        // self.renderCurrentType(); Keep "Featured" as title
        self.filter();
      }
    });

    $('#select-level').selectize({
      onChange: function (value) {
        self.currentFilterSelect.level = [value];
        self.elevationLevelToggle(value);
        if (self.currentFilterSelect.type === 'all') {
          // self.currentFilterType.style.display = 'none';
        }
        self.filter();
      }
    });

    if (window.innerWidth < 870) {
      self.mainContainer.classList.add('is-filter-hide');
    }

    self.filterBtn.addEventListener('click', (e) => {
      self.filterShowToggle();
    });

    self.searchBtn.addEventListener('click', (e) => {
      self.filterShowToggle();
    });

    self.elevationStories.forEach(btn => {
      btn.addEventListener('click', (e) => {
        self.elevationLevelOnClick(e.target.dataset.story);
      });
    });

    self.filter();
    self.stickyFilter(self.resultWrapper.clientHeight);
  },

  filterShowToggle: () => {
    self.mainContainer.classList.toggle('is-filter-hide');
  },

  filter: () => {
    // Lets filter the array where we store the results!
    let filteringArray = self.resultsArray;

    // If TYPE is Featured...
    if ( self.currentFilterSelect.type === 'featured' ) {
      // .. show only featured plans
      filteringArray = filteringArray.filter(result => result.dataset.featured == 1);

      // .. and if LEVEL is not all
      if (self.currentFilterSelect.level[0] !== 'all' ) {
        let levelArray = [];

        // .. grab the level selected only
        self.currentFilterSelect.level.forEach(level => {
          let eachFilterArray = filteringArray.filter(result => result.dataset.level == level);
          levelArray = [...levelArray, ...eachFilterArray];
        });
        filteringArray = levelArray;
      }

    } else {

      // If TYPE is filtered ...
      if (self.currentFilterSelect.type !== 'all') {
        // .. just grab the type selected!
        filteringArray = filteringArray.filter(result => result.dataset.type == self.currentFilterSelect.type);
      }

      // If LEVEL is filtered ...
      if (self.currentFilterSelect.level[0] !== 'all' ) {
        let levelArray = [];

        // .. just grab the level selected!
        self.currentFilterSelect.level.forEach(level => {
          let eachFilterArray = filteringArray.filter(result => result.dataset.level == level);
          levelArray = [...levelArray, ...eachFilterArray];
        });
        filteringArray = levelArray;
      }

    }

    // Setup the results
    self.filteredResults = filteringArray;
    self.renderFilteredResults();
  },

  sortFeaturedPlans: () => {
    const allFeatured = [...document.querySelectorAll('[data-featured="1"]')];
    allFeatured.sort((a, b) => {
      // formerly result__info__plan
      const plan_a = a.querySelector('.result__info__unit').innerText;
      const plan_b = b.querySelector('.result__info__unit').innerText;
      if (plan_a < plan_b) {
        return -1;
      }
      if (plan_a > plan_b) {
        return 1;
      }
      return 0;
    });
    const featuredRender = allFeatured.map(result => result.outerHTML).join('');
    self.resultContainer.innerHTML = featuredRender;
  },

  renderCurrentType: () => {
    if (self.currentFilterSelect.type !== 'all') {
      self.currentFilterType.style.display = 'block';
      self.currentFilterType.innerHTML = self.currentFilterSelect.type.replace(/_/g, ' ');
    } else {
      self.currentFilterType.style.display = 'none';
    }
  },

  renderFilteredResults: () => {
    TweenLite.to(window, 0.1, {
      scrollTo: 'html'
    });

    // after scrolling up to top
    setTimeout(() => {
      if (self.filteredResults.length == 0) {
        self.resultContainer.innerHTML = '<h2 class="result-sorry">Sorry, there are no results that match your filter selections.</h2>';
      } else {
        if (self.currentFilterSelect.featured) {
          self.sortFeaturedPlans();
          self.currentFilterSelect.featured = false;
        } else {
          const renderHTML = self.filteredResults.map(result => result.outerHTML).join('');
          self.resultContainer.innerHTML = renderHTML;
        }

        if (self.currentFilterSelect.type === 'featured') {
          self.sortFeaturedPlans();
        }
      }
      const resultWrapperHeight = document.querySelector('.results-container').clientHeight;
      self.stickyFilter(resultWrapperHeight);
    }, 100);
  },

  elevationLevelOnClick: (level) => {
    let levelInteger = Number(level);
    let levelArray;

    if (levelInteger == 21) {
      levelArray = ['21']
    }
    if (levelInteger >= 10 && levelInteger <= 20) {
      levelArray = ['10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']
    }
    if (levelInteger >= 7 && levelInteger <= 9) {
      levelArray = ['7', '8', '9']
    }
    if (levelInteger >= 4 && levelInteger <= 6) {
      levelArray = ['4', '5', '6']
    }
    if (levelInteger == 3) {
      levelArray = ['3']
    }

    self.currentFilterSelect.level = levelArray;
    self.filter();
    self.elevationLevelToggle(Number(level));
  },

  elevationLevelToggle: (level) => {
    let storyRange;

    if (level == 21) {
      storyRange = '21'
    }
    if (level >= 10 && level <= 20) {
      storyRange = '10'
    }
    if (level >= 7 && level <= 9) {
      storyRange = '7'
    }
    if (level >= 4 && level <= 6) {
      storyRange = '4'
    }
    if (level == 3) {
      storyRange = '3'
    }

    self.elevationStories.forEach(story => {
      story.classList.remove('is-active');
      if (storyRange == story.dataset.story) {
        story.classList.add('is-active');
      }
    });
  },

  stickyFilter: (containerHeight) => {

    if (containerHeight > window.innerHeight) {
      self.stickController = new ScrollMagic.Controller();
      self.stickScene = new ScrollMagic.Scene({
          triggerElement: '.results-container',
          triggerHook: 0,
          duration: `${containerHeight - window.innerHeight}px`,
        })
        .addTo(self.stickController)
        .on("enter", function (e) {
          if (self.stickScene.state() === 'DURING') {
            self.mainContainer.classList.add('is-enter');
            self.mainContainer.classList.remove('is-out');
          }
        })
        .on("leave", function (e) {

          if (self.stickScene.state() === 'AFTER') {
            self.mainContainer.classList.remove('is-enter');
            self.mainContainer.classList.add('is-out');
          };

          if (self.stickScene.state() === 'BEFORE') {
            self.mainContainer.classList.remove('is-enter');
            self.mainContainer.classList.remove('is-out');
          };

        });

    } else {
      self.stickController.destroy();
    }
  }
}

export default self
