'use strict'

import {
  TweenMax,
  TimelineLite
} from 'gsap/umd/TweenMax'
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin'

const self = {
  container: document.querySelector('.form-wrapper'),
  btn: null,
  footer: null,

  init: () => {
    self.setupElements()
    self.btn.addEventListener('click', e => {
      //self.formDisplay()
      window.open('https://rennie.as.me/schedule.php?calendarID=3950453&field:7609500=7013Z0000033kuoQAA&field:7609499=a04f100000W7VuwAAF&field:7618423=Project%20Site', '_blank')
    })
  },

  setupElements: () => {
    self.container = document.querySelector('.form-wrapper')
    self.btn = document.querySelector('.waitlist-form__title')
    // self.btnSpan = document.querySelector('.waitlist-form__title span')
    self.footer = document.querySelector('footer')
  },

  formDisplay: () => {
    const tlDisplay = new TimelineLite()
    tlDisplay
      .set(self.footer, {
        className: '+=js-open'
      })
      .set(self.container, {
        display: 'block'
      })
      // .set(self.btnSpan, { display: 'inline' })
      .to(window, 0.6, {
        scrollTo: 'footer'
      })
      // .to(self.btnSpan, 0.3, { autoAlpha: 1 }, '-=0.1')
      .to(self.container, 0.6, {
        autoAlpha: 1,
        y: 0
      }, '-=0.3')
  }
}

export default self
