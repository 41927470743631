'use strict'

import Swiper from 'swiper'

const self = {
  container: document.querySelector('.westend .swiper-container'),

  init: () => {
    self.container = document.querySelector('.westend .swiper-container');
    self.westendSlider()
  },

  westendSlider: () => {
    const slider = new Swiper(self.container, {
      speed: 600,
      effect: 'fade',
      loop: true,
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    });
  }

}

export default self