'use strict'

import { TweenMax, TimelineLite } from 'gsap/umd/TweenMax'
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin'

let self
export default (self = {
  container: document.querySelector('.main-single-journal'),
  images: null,
  zoomWrapper: null,
  zoomImage: null,
  zoomClose: null,
  bgWhite: null,
  positionX: null,
  positionY: null,
  imageWidth: null,
  imageSrc: null,

  init: () => {
    self.setupElements()
    self.registerEvents()
    self.zoomIn()
  },

  setupElements: () => {
    self.container = document.querySelector('.main-single-journal')
    self.images = [...document.querySelectorAll('.s-journal-center img')]
    self.zoomWrapper = document.querySelector('.zoom-wrapper')
    self.zoomImage = document.querySelector('.zoom-target img')
    self.bgWhite = document.querySelector('.zoom-bg-white')
    self.zoomClose = document.querySelector('.zoom-close')
  },

  registerEvents: () => {
    self.zoomImage.addEventListener('click', e => {
      self.zoomOut()
    })
    self.zoomClose.addEventListener('click', e => {
      self.zoomOut()
    })
  },

  zoomIn: () => {
    self.images.forEach(image => {
      image.addEventListener('click', e => {
        if (!e) e = window.event

        const clickTarget = e.target || e.srcElement

        const tl = new TimelineLite()

        self.positionX = clickTarget.getBoundingClientRect().left
        self.positionY = clickTarget.getBoundingClientRect().top
        self.imageWidth = clickTarget.clientWidth
        self.imageSrc = clickTarget.src

        return tl
          .add(() => {
            self.zoomWrapper.style.display = 'block'
            self.bgWhite.style.display = 'block'
            self.zoomWrapper.style.top = `${self.positionY}px`
            self.zoomWrapper.style.left = `${self.positionX}px`
            self.zoomWrapper.style.width = `${self.imageWidth}px`
            self.zoomImage.src = self.imageSrc
            TweenLite.to(self.zoomWrapper, 1, { scrollTo: 'html' })
          })
          .add('zoom')
          .to(self.zoomWrapper, 0.6, { padding: '80px 0', top: '0', left: '0', width: '100%' }, 'zoom')
          .to(self.zoomImage, 0.6, { width: '85%' }, 'zoom')
          .to(self.bgWhite, 0.3, { autoAlpha: 1 }, 'zoom')
          .add(() => {
            const contentHeight = self.zoomWrapper.scrollHeight
            self.bgWhite.style.height = `${contentHeight}px`
            // self.zoomWrapper.style.height = `${contentHeight}px`;
            self.zoomWrapper.style.height = `100vh`
            document.body.style.overflow = 'hidden'
          })
          .add(() => {
            self.zoomWrapper.style.overflowY = 'auto'
          })
          .to(self.zoomClose, 0.3, { autoAlpha: 1 })
      })
    })
  },

  zoomOut: () => {
    const tl = new TimelineLite()

    return tl
      .to(self.zoomClose, 0.1, { autoAlpha: 0 })
      .add('start')
      .to(self.zoomWrapper, 0.6, { top: `${self.positionY}px`, left: `${self.positionX}px`, padding: '0', width: `${self.imageWidth}px`, transform: 'none', overflow: 'visible' }, 'start')
      .to(self.zoomImage, 0.6, { width: '100%' }, 'start')
      .to(self.bgWhite, 0.3, { autoAlpha: 0 })
      .set(self.zoomWrapper, { display: 'none', clearProps: 'all' })
      .set(document.body, { overflow: 'visible' })
  }
})
