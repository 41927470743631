'use strict'

import Barba from 'barba.js'
import {
  TweenLite,
  TimelineLite
} from 'gsap'
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin'
import CustomBodymovin from './bodyMovin'

// Modules
import JournalNav from './journalNav'
import ZoomInOutImage from './zoomInOutImage'
import PanoramaViewer from './panoramaViewer'
import WestEndMap from './westEndMap'
import ScrollDownForm from './scrollDownForm'
import FloorPlansFilter from './floorplansFilter'
import HomeSlider from './homeSlider'
import NeighourhoodSlideshow from './neighourhoodSlideshow'
import Accordion from './accordion'
import ScrollTo from './scrollTo'
import ViewAll from './viewAll'
import ViewsTab from './viewsTab'
import SalesforceForm from './salesforceForm'

const self = {
  lastElementClicked: null,

  transitionElms: {
    body: document.body,
    transitionContainer: document.getElementById('js-transition-container'),
    whiteBg: document.getElementById('js-white-bg'),
    topTiles: [...document.querySelectorAll('.js-white-bg__top .js-white-bg__tile .js-white-bg__tile__inner')],
    bottomTiles: [...document.querySelectorAll('.js-white-bg__bottom .js-white-bg__tile .js-white-bg__tile__inner')],
    logoWrapper: document.getElementById('js-logo'),
    logo: new CustomBodymovin({
      elm: document.getElementById('js-logo'),
      animationData: 'logo'
    })
  },

  init: () => {
    Barba.Pjax.init()
    Barba.Prefetch.init()

    self.registerEvents()
    self.eachPageFunctionInit()
    self.pageTransition()
  },

  registerEvents: () => {
    // Google Analytics
    Barba.Dispatcher.on('initStateChange', function () {
      if (typeof ga === 'function') {
        ga('send', 'pageview', location.pathname)
      }
    })

    // Prevent Reloading When User click a link that is a same page as a current page
    Barba.Dispatcher.on('transitionCompleted', function (currentStatus) {
      const links = document.querySelectorAll('a[href]')
      const cbk = function (e) {
        if (e.currentTarget.href === window.location.href) {
          e.preventDefault()
          e.stopPropagation()
        }
      }
      for (let i = 0; i < links.length; i++) {
        links[i].addEventListener('click', cbk)
      }
    })

    Barba.Dispatcher.on('linkClicked', function (el) {
      self.lastElementClicked = el
    })
  },

  pageTransition: () => {
    const PageAnimation = Barba.BaseTransition.extend({
      start: function () {
        Promise.all([this.newContainerLoading])
          .then(this.fadeInOut.bind(this))
          .then(this.pageCheck.bind(this))
      },

      fadeInOut: function () {
        const _this = this
        const tlCover = new TimelineLite()

        return tlCover
          .to(this.oldContainer, 0.6, {
            autoAlpha: 0
          })
          .add(() => {
            TweenLite.set(window, {
              scrollTo: 'html'
            })
            TweenLite.set(['.main-nav li', '.main-nav'], {
              clearProps: 'all'
            })
            TweenLite.set(self.transitionElms.body, {
              className: '-=is-open',
              overflow: 'visible'
            })
          })
          .set(this.oldContainer, {
            display: 'none'
          })
          .to(this.newContainer, 0.9, {
            autoAlpha: 1
          })
          .set([this.newContainer, 'main'], {
            clearProps: 'all'
          })
          .add(() => {
            if (this.newContainer.dataset.namespace == 'home' && window.innerWidth > 870) {
              document.documentElement.style.overflow = 'hidden'
            } else {
              document.documentElement.style.overflow = 'visible'
            }
            _this.done()
          }, '+0.6')
      },

      pageCheck: function () {
        const page = this.newContainer.dataset.namespace
        const oldPage = this.oldContainer.dataset.namespace

        setTimeout(() => {
          if (page !== 'home') {
            this.newContainer.ownerDocument.body.classList.remove('front')
          } else {
            this.newContainer.ownerDocument.body.classList.add('front')
          }
          if (page !== 'neighbourhood') {
            this.newContainer.ownerDocument.body.classList.remove('neighbourhood-page')
          } else {
            this.newContainer.ownerDocument.body.classList.add('neighbourhood-page')
          }
          if (page !== 'floorplan') {
            this.newContainer.ownerDocument.body.classList.remove('page-template-page-floorplans')
          } else {
            this.newContainer.ownerDocument.body.classList.add('page-template-page-floorplans')
          }
          if (page == 'thanks') {
            this.newContainer.ownerDocument.body.classList.add('page-template-page-thank-you')
          }
          if (oldPage == 'thanks') {
            this.newContainer.ownerDocument.body.classList.remove('page-template-page-thank-you')
          }
        }, 1000)

        const url = this.newContainer.baseURI
        const navList = [...document.querySelectorAll('.main-nav a')]

        navList.forEach(list => {
          list.classList.remove('active')
          if (list.href == url) {
            list.classList.add('active')
          }
        })
      }
    })

    Barba.Pjax.getTransition = function () {
      return PageAnimation
    }
  },

  eachPageFunctionInit: () => {
    const basic = Barba.BaseView.extend({
      namespace: 'basic',
      onEnter: function () {
        this.SalesforceForm = null
        this.ScrollTo = null
      },
      onEnterCompleted: function () {
        this.SalesforceForm = SalesforceForm
        this.SalesforceForm.init()
        this.ScrollDownForm = ScrollDownForm
        this.ScrollDownForm.init()
        this.ScrollTo = ScrollTo
        this.ScrollTo.init()
      }
    })
    basic.init()

    const singleFloorplan = Barba.BaseView.extend({
      namespace: 's-floorplan',
      onEnter: function () {
        this.SalesforceForm = null
        this.PanoramaViewer = null
        this.ScrollTo = null
      },
      onEnterCompleted: function () {
        this.SalesforceForm = SalesforceForm
        this.SalesforceForm.init()
        this.ScrollDownForm = ScrollDownForm
        this.ScrollDownForm.init()
        this.PanoramaViewer = PanoramaViewer
        this.PanoramaViewer.init()
        this.ScrollTo = ScrollTo
        this.ScrollTo.init()
      }
    })
    singleFloorplan.init()

    const singleJournal = Barba.BaseView.extend({
      namespace: 's-journal',
      onEnter: function () {
        this.SalesforceForm = null
        this.JournalNav = null
        this.ZoomInOutImage = null
        this.ScrollTo = null
      },
      onEnterCompleted: function () {
        this.SalesforceForm = SalesforceForm
        this.SalesforceForm.init()
        this.JournalNav = JournalNav
        this.JournalNav.init()
        this.ZoomInOutImage = ZoomInOutImage
        this.ZoomInOutImage.init()
        this.ScrollDownForm = ScrollDownForm
        this.ScrollDownForm.init()
        this.ScrollTo = ScrollTo
        this.ScrollTo.init()
      }
    })
    singleJournal.init()

    const home = Barba.BaseView.extend({
      namespace: 'home',
      onEnter: function () {
        this.SalesforceForm = null
        this.HomeSlider = null
        this.ScrollTo = null
      },
      onEnterCompleted: function () {
        this.SalesforceForm = SalesforceForm
        this.SalesforceForm.init()
        this.HomeSlider = HomeSlider
        this.HomeSlider.init()
        this.ScrollDownForm = ScrollDownForm
        this.ScrollDownForm.init()
        this.ScrollTo = ScrollTo
        this.ScrollTo.init()
      }
    })
    home.init()

    const neighbourhood = Barba.BaseView.extend({
      namespace: 'neighbourhood',
      onEnter: function () {
        this.SalesforceForm = null
        this.WestEndMap = null
        this.NeighourhoodSlideshow = null
        this.ScrollTo = null
      },
      onEnterCompleted: function () {
        this.SalesforceForm = SalesforceForm
        this.SalesforceForm.init()
        this.WestEndMap = WestEndMap
        this.WestEndMap.init()
        this.ScrollDownForm = ScrollDownForm
        this.ScrollDownForm.init()
        this.NeighourhoodSlideshow = NeighourhoodSlideshow
        this.NeighourhoodSlideshow.in
        this.ScrollTo = ScrollTo
        this.ScrollTo.init()
      }
    })
    neighbourhood.init()

    const floorplan = Barba.BaseView.extend({
      namespace: 'floorplan',
      onEnter: function () {
        this.SalesforceForm = null
        this.FloorPlansFilter = null
        this.ScrollTo = null
      },
      onEnterCompleted: function () {
        this.SalesforceForm = SalesforceForm
        this.SalesforceForm.init()
        this.ScrollDownForm = ScrollDownForm
        this.ScrollDownForm.init()
        this.FloorPlansFilter = FloorPlansFilter
        this.FloorPlansFilter.init()
        this.ScrollTo = ScrollTo
        this.ScrollTo.init()
      }
    })
    floorplan.init()

    const views = Barba.BaseView.extend({
      namespace: 'views',
      onEnter: function () {
        this.SalesforceForm = null
        this.ViewsTab = null
        this.PanoramaViewer = null
        this.ScrollTo = null
        // initialize this first before ViewsTab
        this.PanoramaViewer = PanoramaViewer
        this.PanoramaViewer.init()
      },
      onEnterCompleted: function () {
        this.SalesforceForm = SalesforceForm
        this.SalesforceForm.init()
        this.ScrollDownForm = ScrollDownForm
        this.ScrollDownForm.init()
        this.ViewsTab = ViewsTab
        this.ViewsTab.init()
        this.PanoramaViewer = PanoramaViewer
        this.PanoramaViewer.init()
        this.ScrollTo = ScrollTo
        this.ScrollTo.init()
      }
    })
    views.init()

    const amenities = Barba.BaseView.extend({
      namespace: 'amenities',
      onEnter: function () {
        this.SalesforceForm = null
        this.ViewAll = null
        this.Accordion = null
        this.ScrollTo = null
      },
      onEnterCompleted: function () {
        this.SalesforceForm = SalesforceForm
        this.SalesforceForm.init()
        this.ScrollDownForm = ScrollDownForm
        this.ScrollDownForm.init()
        this.ViewAll = ViewAll
        this.ViewAll.init()
        this.Accordion = Accordion
        this.Accordion.init()
        this.ScrollTo = ScrollTo
        this.ScrollTo.init()
      }
    })
    amenities.init()
    views.init()

    const interiors = Barba.BaseView.extend({
      namespace: 'interiors',
      onEnter: function () {
        this.SalesforceForm = null
        this.Accordion = null
        this.ScrollTo = null
      },
      onEnterCompleted: function () {
        this.SalesforceForm = SalesforceForm
        this.SalesforceForm.init()
        this.Accordion = Accordion
        this.Accordion.init()
        this.ScrollTo = ScrollTo
        this.ScrollTo.init()
      }
    })
    interiors.init();

    // Sign Up page
    const signUp = Barba.BaseView.extend({
      namespace: 'sign-up',
      onEnter: function () {
        this.SalesforceForm = null
      },
      onEnterCompleted: function () {
        this.SalesforceForm = SalesforceForm
        this.SalesforceForm.init()
      }
    })
    signUp.init()
  }
}

export default self
